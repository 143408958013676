import React, { useEffect, useRef } from 'react'
import Logo from "../../Logo";
import { Box, Button, Card, Tab, Tabs } from "@mui/material";
import { useState } from "react";

import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import {
    fetchInvoicesSevenDays,
    syncInvoicesLaudus,
    syncInvoicesPayments,
} from "../../../services/invoiceService";
import { useAppSelector } from '../../../hooks/storeHooks';
import { InvoicesView } from './InvoicesView';
import { TrucksView } from './TrucksView';
import { fetchTrucks } from '../../../services/truckService';

const LogisticView = () => {

    const { isLoading } = useAppSelector((state: any) => state.invoiceReducer);
    const [tab, setTab] = useState<"invoices" | "trucks">("invoices");
    const effectRan = useRef(false);
    useEffect(() => {
        if (!effectRan.current) {
            effectRan.current = true;
            fetchInvoicesSevenDays();
            fetchTrucks();
        }
    }, []);

    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-3">Logística</div>
            <div className="mb-1 flex gap-2 pr-7">
                <Button
                    variant="contained"
                    onClick={async () => {
                        await syncInvoicesLaudus();
                        await fetchInvoicesSevenDays();
                    }}
                >
                    Sincronizar Facturas
                </Button>
                <Button
                    variant="contained"
                    onClick={async () => {
                        await syncInvoicesPayments();
                        await fetchInvoicesSevenDays();
                    }}
                >
                    Sincronizar Pagos
                </Button>
            </div>
            <div>
                {isLoading ? (
                    <div className="flex justify-center w-full col-span-full">
                        <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    </div>
                ) : (
                    <div className="h-full flex flex-col overflow-auto">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tab}
                                onChange={(_, v) => {
                                    setTab(v as "invoices" | "trucks");
                                }}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Facturas" value={"invoices"} />
                                <Tab label="Camiones" value={"trucks"} />
                            </Tabs>
                        </Box>
                        <div className="flex-1 overflow-auto">
                            {tab === "invoices" && <InvoicesView />}
                            {tab === "trucks" && <TrucksView />}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LogisticView
